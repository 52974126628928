<template>
  <div class="home">
    <v-carousel
      hide-delimiters
      hide-arrows
      :left-control-icon="false"
      :right-control-icon="false"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
    <!-- <div class="container">
      <backtospeisekarte></backtospeisekarte>
    </div> -->
    <v-card>
      <v-card-title class="display-3">
        Gemischter Teller
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:items="{ item }"
          ><b>{{ item.desserts.speise }}</b></template
        >
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
// import Backtospeisekarte from './Backtospeisekarte.vue';
export default {
  // components: {
  //   Backtospeisekarte,
  // },
  name: 'Gemischterteller',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/gemischtertellermithintergrund.jpg'),
        },
        {
          src: require('../assets/images/gemischtertellerseitlich.jpg'),
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Inhalt', value: 'inhalt' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          nummer: 'L1',
          speise: 'Sushi-Sashimi Teller',
          inhalt: '(10 Sushi, 10 Sashimi)',
          allergene: '(A, B, C, D, F, G, R, O)',
          preis: '22,50 €',
        },
        {
          nummer: 'L2',
          speise: 'Sushi-Maki Teller',
          inhalt:
            '(2 Futo Maki, 3 Lachs Maki, 1 California Temaki, 2 Thunfisch Sushi, 2 Ebi Sushi, 3 Lachs Sushi)',
          allergene: '(A, B, C, D, F, G, R, O)',
          preis: '15,90 €',
        },
        {
          nummer: 'L3',
          speise: 'Spezialitätenplatte für 2 Personen',
          inhalt:
            '(Suppe, 6 Sashimi, 6 Maki, 2 gegrillte Garnelen, 3 gegrillte Muscheln, 2 Scheiben gegrillter Lachs)',
          allergene: '(A, B, D, F, G, H, O, R)',
          preis: '49,00 €',
        },
        {
          nummer: 'L4',
          speise: 'Spezialitätenplatte',
          inhalt:
            '(Suppe, knusprige Ente oder gebackenes Huhn mit Sesam, 5 Sushi, 3 California Maki, 3 gegrillte Muscheln, 2 Scheiben gegrillter Lachs',
          allergene: '(A, B, D, F, N, R, G, O)',
          preis: '19,50 €',
        },
        {
          nummer: 'L5',
          speise: 'Spezialitätenplatte',
          inhalt:
            '(Suppe, 3 California Maki, 3 Lachs Maki, 2 Futo Maki, 3 Sushi, gebratenes Rindfleisch mit Zwiebeln oder gebratenes Huhn mit Gemüse)',
          allergene: '(A, C, D, E, F, G, R, O)',
          preis: '18,20 €',
        },
        {
          nummer: 'L6',
          speise: 'Sushi-Sashimi-Maki Teller',
          inhalt: '(Suppe, 9 Sashimi, 6 Sushi, 6 Maki, 1 Temaki, 1 Futo Maki)',
          allergene: '(A, B, C, D, F, G, H, N, O)',
          preis: '25,50 €',
        },
        {
          nummer: 'L7',
          speise: 'Sushi-Sashimi-Maki für 2 Personen Teller',
          inhalt:
            '(Suppe, 16 Sashimi, 12 Sushi, 12 Maki, 2 Temaki, 2 Futo Maki)',
          allergene: '(A, B, C, D, E, F, H, N, O, R)',
          preis: '69,00 €',
        },
        {
          nummer: 'L8',
          speise: 'Sushi-Sashimi-Maki für 4 Personen Teller',
          inhalt:
            '(Suppe, 32 Sashimi, 24 Sushi, 24 Maki, 4 Temaki, 4 Futo Maki)',
          allergene: '(A, C, D, E, N, O)',
          preis: '99,00 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
