<template>
  <div class="home">
    <v-carousel hide-delimiters cycle>
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <v-row class="fill-height" align="center" justify="center">
          <div class="text-h1 white--text">
            {{ item.title }}
          </div>
        </v-row></v-carousel-item
      >
    </v-carousel>
    <!-- <div class="container">
      <backtovorspeise></backtovorspeise>
    </div> -->
    <v-card>
      <v-card-title class="display-3">
        Dim-Sum
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
// import Backtovorspeise from './Backtovorspeise.vue';
export default {
  // components: { Backtovorspeise },
  name: 'Dimsum',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/dimsum-uncutted-background.png'),
          title: '',
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Anzahl', value: 'anzahl' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          nummer: 'D1',
          speise: 'gedämpfte Fleischtaschen nach Shanghai-Art',
          anzahl: '(4 Stk.)',
          allergene: '(A, F)',
          preis: '6,10 €',
        },
        // DERZEIT NICHT VERFÜGBAR
        // {
        //   nummer: 'D2',
        //   speise: 'Reismehltaschen mit Garnelen und Bärlauch',
        //   anzahl: '(4 Stk.)',
        //   allergene: '(A, F, R)',
        //   preis: '5,80 €',
        // },
        {
          nummer: 'D3',
          speise: 'Reismehltaschen mit Garnelen',
          anzahl: '(4 Stk.)',
          allergene: '(A, F, R)',
          preis: '6,10 €',
        },
        // {
        //   nummer: 'D4',
        //   speise: 'Teigtaschen mit Schweinefleisch',
        //   anzahl: '(4 Stk.)',
        //   allergene: '(A, F)',
        //   preis: '5,80 €',
        // },
        // {
        //   nummer: 'D5',
        //   speise: 'Knödel mit gegrilltem Schweinefleisch',
        //   anzahl: '(4 Stk.)',
        //   allergene: '(A, F)',
        //   preis: '5,80 €',
        // },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
