<template>
  <div class="home">
    <v-carousel
      hide-delimiters
      hide-arrows
      :left-control-icon="false"
      :right-control-icon="false"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
    <!-- <div class="container">
      <backtohauptspeise></backtohauptspeise>
    </div> -->
    <v-card>
      <v-card-title class="display-3">
        Gemüse
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
// import Backtohauptspeise from './Backtohauptspeise.vue';
export default {
  // components: { Backtohauptspeise },
  name: 'Gemuese',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/gebratenenudelnmithintergrund.jpg'),
        },
        {
          src: require('../assets/images/gebratenerreismithintergrund.jpg'),
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          nummer: '171',
          speise: 'Gebratenes Tofu mit Gemüse',
          allergene: '(A, C, E, F, G, N)',
          preis: '11,00 €',
        },
        // {
        //   nummer: '172',
        //   speise: 'Gebratene Sojabohnensprossen',
        //   allergene: '(A, C, F, G, N)',
        //   preis: '8,10 €',
        // },
        {
          nummer: '173',
          speise: 'Gebratenes Gemüse',
          allergene: '(A, C, F, G, N)',
          preis: '9,90 €',
        },
        // {
        //   nummer: '174',
        //   speise: 'Gebackenes Gemüse',
        //   allergene: '(A, C, F, G, N)',
        //   preis: '8,90 €',
        // },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
