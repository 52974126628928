<template>
  <div class="home">
    <v-carousel hide-delimiters cycle>
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <v-row class="fill-height" align="center" justify="center">
          <div class="text-h1 white--text">
            {{ item.title }}
          </div>
        </v-row></v-carousel-item
      >
    </v-carousel>
    <!-- <div class="container">
      <backtonachspeise></backtonachspeise>
    </div> -->
    <v-card>
      <v-card-title class="display-3">
        Nachspeise
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
// import Backtonachspeise from './Backtonachspeise.vue';
export default {
  //   components: { Backtonachspeise },
  name: 'Gebackenefruechte',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/gebackenebanane.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/eiskaffeemithintergrund.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/gebackenebananegerade.jpg'),
          title: '',
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          nummer: 'H1',
          speise: 'gebackene Banane',
          allergene: '(A, C, H, O)',
          preis: '5,30 €',
        },
        {
          nummer: 'H2',
          speise: 'gebackene Äpfel',
          allergene: '(A, C, H, O)',
          preis: '5,30 €',
        },
        {
          nummer: 'H3',
          speise: 'gebackene Ananas',
          allergene: '(A, C, H, O)',
          preis: '5,30 €',
        },
        {
          nummer: 'H4',
          speise: 'gebackene gemischte Früchte',
          allergene: '(A, C, H, O)',
          preis: '6,20 €',
        },
        {
          nummer: 'H5',
          speise: 'gebackene gemischte Früchte mit Eis',
          allergene: '(A, C, H, O)',
          preis: '7,00 €',
        },
        {
          nummer: 'H6',
          speise: 'Lychee Kompott',
          allergene: '(A, C, H, O)',
          preis: '5,50 €',
        },
        {
          nummer: 'E1',
          speise: 'gemischtes Eis mit Obst',
          allergene: '(A, H, G)',
          preis: '6,30 €',
        },
        {
          nummer: 'E2',
          speise: 'Bananensplit',
          allergene: '(A, H, G)',
          preis: '5,90 €',
        },
        {
          nummer: 'E3',
          speise: 'Eiskaffee',
          allergene: '(A, H, G)',
          preis: '5,80 €',
        },
        {
          nummer: 'E4',
          speise: 'Gemischtes Eis mit Schlagobers und Schoko Sauce',
          allergene: '(A, E, H, G)',
          preis: '6,00 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
